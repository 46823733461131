/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
// $warning: #f8991d;
// $info: #3FA592;

$navbar-height: 9vh;
$footer-height: 26vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #ee3270;
    color: #fff;

    .nav-link {
        color: #fff;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #623667;
        border-radius: 2px;
    }
}

#wrapper {
    min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #ee3270;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        // color: #696767
        background-color: #623667;
    }
}

.brand-logo {
    height: 100px;
    width: auto;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
    background-color: #623667 !important;
  }
  .swiper-button-prev {
    color: #623667 !important;
  }
  .swiper-button-next {
    color: #623667 !important;
  }
  .swiper-wrapper{
      height: 94% !important; 
  }

.justificado {
    text-align: justify;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"